import { render, staticRenderFns } from "./faults.vue?vue&type=template&id=009cc060&scoped=true"
import script from "./faults.vue?vue&type=script&lang=js"
export * from "./faults.vue?vue&type=script&lang=js"
import style0 from "./faults.vue?vue&type=style&index=0&id=009cc060&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009cc060",
  null
  
)

export default component.exports