<template>
  <div v-if="malfunctions" class="faults__block main-block">
    <div class="main-block__head">
      <div class="flex">
        <h1 class="main-block__title">Неисправности</h1>
        <span class="faults__head-number default-btn">{{ malfunctions.length }}</span>
      </div>
      <img class="faults__head-icon" src="@/assets/png/select-icon.png" alt="" />
    </div>
    <ul class="faults__items main-block__box">
      <li
        v-for="item in malfunctions"
        :key="item.id"
        class="faults__item flex"
        @click="this.$router.push({name: 'Route_show', params: {id: item.order_id}})"
      >
        <img class="faults__img" src="@/assets/png/inblock-error.png" alt="" />
        <div class="faults__box flex">
          <div class="faults__text-block">
            <p class="faults__name">
              {{ item.title }}
            </p>
            <p class="faults__text">
              <label class="faults__label" for="">{{ item.image }}</label>
            </p>
          </div>
          <div class="faults__info-block">
            <p v-if="true" class="faults__text">
              {{ item.description }}
            </p>
            <div class="faults__date-box flex">
              <img v-if="true" class="" src="@/assets/png/clock.png" alt="" />
              <label class="faults__date" for="">
                {{ item.date ? item.date : "" }}
              </label>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "Помеченным" },
        { value: "a", text: "Помеченным" },
      ],
    };
  },
  async created() {
    if(!this.malfunctions)
      await this.$store.dispatch("getMalfunctions");
  },
  computed: {
    ...mapState(["malfunctions"]),
  },
};
</script>

<style scoped>
.faults__block {
  padding: 20px 20px 10px 20px;
  min-height: 350px;
}
.faults__head-number {
  padding: 5px;
  margin-left: 10px;
  height: auto;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
  background: rgba(231, 76, 60, 0.15);
  color: #e74c3c;
}
.faults__head-icon {
  transform: rotate(270deg);
}
.faults__items {
  max-height: 270px;
}
.faults__item {
  margin-top: 6px;
  padding: 10px 0 0 0;
  align-items: flex-start;
  cursor: pointer;
  border-radius: 5px;
}
.faults__item:hover {
  background-color: #f2f2f2;
}
.faults__item:not(:last-child) {
  height: 65px;
}
.faults__item:not(:last-child) .faults__box {
  border-bottom: 1px solid #f2f2f8;
  padding-bottom: 10px;
}
.faults__img {
  width: 15%;
  height: 30px;
  max-width: 30px;
  margin-right: 10px;
}
.faults__box {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  flex-direction: column;
  align-items: start;
  width: 100%;
  position: relative;
}
.faults__text-block {
  width: 65%;
}
.faults__info-block {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}
.faults__info {
  width: 35%;
}
.faults__name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 2px;
}
.faults__text {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #707070;
  margin: 0;
}
.faults__label {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #acacac;
}
.faults__date-box {
  width: 35%;
  text-align: right;
  justify-content: center;
}
.faults__date {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  margin: 0 0 0 5px;
}
.faults-favorite {
  width: 25px;
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>
